import React from "react"

import { useTranslation } from "@hooks"

const NoResults = () => {
  const [t] = useTranslation()
  return <p className="has-text-centered">{t("components.NoResults.text")}</p>
}

export default NoResults
